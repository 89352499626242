import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import OemLogo from "../images/shop-images/oem-stamp.png"
import TireImage from "../images/shop-wheel.jpg"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 2rem auto;
  /* border: 1px solid black; */
`

const PageHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid blue; */

  #header-title {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
  }

  #header-form {
    width: 500px;
  }
  img {
    width: 120px;
  }
`

const Input = styled.input`
  width: 70%;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0 0.7rem;
`
const SearchBtn = styled.button`
  background-color: orange;
  border: 1px solid orange;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  /* margin: 0 0.5rem; */
`

const PartShopBodyLayout = styled.div`
  /* height: 500px; */
  margin: 2rem auto;
  display: flex;
  grid-template-columns: 40% 40% 20%;
  grid-gap: 15px;

  /* @media (max-width: 1490px) {
    grid-template-columns: 30% 40% 20%;
  } */
`
const ImageContainer = styled.div`
  /* border: 1px solid black; */
  max-width: 500px;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`
const PartsInfoContainer = styled.div`
  #info-header {
    p,
    h2 {
      margin: 0;
    }
    p {
      font-size: 1.3em;
    }
    border-bottom: 1px solid lightgrey;
  }

  #info-body {
    p {
      font-size: 1.2em;
    }
  }

  select {
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.3rem;
  }
`
const AddToCartContainer = styled.div`
  width: 300px;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 7px;
`
const CartBtn = styled.button`
  /* width: 80%; */
  background-color: yellow;
  background-image: linear-gradient(yellow, orange);
  border: 1px solid black;
  padding: 0.3rem;
  border-radius: 5px;
  margin: 1rem 0;
  cursor: pointer;
`

const AddedItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  /* width: 80%; */
  background-color: whitesmoke;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem;

  .fa-window-close {
    position: absolute;
    color: red;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  #cart-img {
    width: 70px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const PartsTemplate = () => {
  const [qty, setQty] = useState("1")
  const [price, setPrice] = useState("0")
  const [displayItem, setDisplayItem] = useState("none")
  const [itemsInCart, setItemsInCart] = useState("Cart is Empty")

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  const GetQuantity = e => {
    setQty(e.target.value)
  }

  const AddToCart = () => {
    setPrice("7.90")
    setDisplayItem("flex")
    setItemsInCart("")
  }

  const RemoveItem = () => {
    setDisplayItem("none")
    setPrice("0")
    setQty("1")
    setItemsInCart("Cart is Empty")
  }
  return (
    <Layout>
      <PageLayout>
        <PageHeader>
          <div id="header-title">
            <img src={OemLogo} alt="FactoryCat genuine OEM RPS parts" />
            <h1>FactoryCat - Factory Direct</h1>{" "}
          </div>
          <div id="header-form">
            <form>
              <Input
                type="text"
                name="search"
                placeholder="Search Part Number"
              />
              <SearchBtn>
                <i className="fas fa-search" /> Search
              </SearchBtn>
            </form>
          </div>
        </PageHeader>
        <PartShopBodyLayout>
          <ImageContainer>
            <img src={TireImage} alt="some dynamic data" />{" "}
          </ImageContainer>
          <PartsInfoContainer>
            <div id="info-header">
              <h2>FactoryCat Part Number: 21-7570</h2>
              <p>Back up Wheel</p>
            </div>
            <div id="info-body">
              <p>
                Price: <span style={{ color: "red" }}>$7.90</span>
                <br />
                <span style={{ fontSize: ".6em" }}>
                  Pricing shown does not include freight, duties, taxes, or
                  other applicable fees.
                </span>
              </p>
              <p>Availability: In Stock</p>
              <p>Net Weight: 0.11 lbs</p>
              <select value={qty} onChange={GetQuantity}>
                <option value="1">Qty 1</option>
                <option value="2">Qty 2</option>
                <option value="3">Qty 3</option>
                <option value="4">Qty 4</option>
                <option value="5">Qty 5</option>
                <option value="6">Qty 6</option>
                <option value="7">Qty 7</option>
                <option value="8">Qty 8</option>
                <option value="9">Qty 9</option>
                <option value="10">Qty 10</option>
                <option value="11">Qty 11</option>
                <option value="12">Qty 12</option>
                <option value="13">Qty 13</option>
                <option value="14">Qty 14</option>
                <option value="15">Qty 15</option>
                <option value="16">Qty 16</option>
                <option value="17">Qty 17</option>
                <option value="18">Qty 18</option>
                <option value="19">Qty 19</option>
                <option value="20">Qty 20</option>
              </select>
              <br />
              <CartBtn onClick={AddToCart}>
                <i className="fas fa-shopping-cart" /> Add to Cart
              </CartBtn>
              <p style={{ marginTop: "100px" }}>
                All parts listed on our website are FactoryCat OEM Parts for our
                Scrubbers, Sweepers, Burnishers, and EDGE equipment.
              </p>
            </div>
          </PartsInfoContainer>

          <AddToCartContainer>
            <h3 style={{ margin: "0", borderBottom: "1px solid lightgrey" }}>
              Shopping Cart
            </h3>
            <p style={{ color: "red", fontSize: "1.2em" }}>
              $7.90{" "}
              <span style={{ color: "black", fontSize: ".7em" }}>
                + shipping and FEES
              </span>
            </p>
            <p>
              Arrives: <span style={{ fontWeight: "bold" }}>Feb 10 - 17</span>
            </p>
            <p style={{ color: "green", fontSize: "1.2em" }}>In Stock</p>
            <h3>
              Items: <span style={{ color: "red" }}>{itemsInCart}</span>
            </h3>

            <AddedItem style={{ display: displayItem }}>
              <i onClick={RemoveItem} className="fas fa-window-close" />
              <div id="cart-info">
                <p>
                  Part No. <span>21-7570</span>
                </p>
                <p>Back up Wheel</p>
                <p>
                  Qty: <span>{qty}</span>
                </p>
              </div>
              <div id="cart-img">
                <img src={TireImage} alt="" />
              </div>
            </AddedItem>
            <p style={{ display: displayItem }}>
              Item Total: <span style={{ color: "green" }}> {qty}</span>
            </p>
            <p>
              Cart Total:{" "}
              <span style={{ color: "red" }}>
                {" "}
                {formatter.format(price * qty)}
              </span>
            </p>
            <CartBtn>
              <i className="fas fa-shopping-cart" /> Check Out
            </CartBtn>
          </AddToCartContainer>
        </PartShopBodyLayout>
      </PageLayout>
    </Layout>
  )
}

export default PartsTemplate
